import React from "react"
import { Box, Image } from "theme-ui"

export default function LinkCard({ src, bgGradient, imgWidth }) {
  return (
    <Box
      sx={{
        position: "relative",
        backgroundImage: `linear-gradient(${bgGradient})`,
        height: [200, 400, 200, 400],
        border: "1px solid",
        mb: 4
      }}
    >
      <Image
        src={src}
        sx={{
          position: "absolute", 
          width: imgWidth, 
          bottom: 0,
          left: "50%",
          transform: "translate(-50%, 0)"
        }}
      />
    </Box>
  )
}
