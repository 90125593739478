import React from "react"
import { Grid, Box, Text } from "theme-ui"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Breadcrumbs from "../../components/Breadcrumbs"
import LinkCard from "../../components/LinkCard"
import EdgeOutImage from "../../components/EdgeOutImage"

import fighterPortraitImage from "../../images/role-portraits/fighter-cutout.png"
import invokerPortraitImage from "../../images/role-portraits/invoker-cutout.png"
import rangerPortraitImage from "../../images/role-portraits/ranger-cutout.png"
import naturalistPortraitImage from "../../images/role-portraits/naturalist-cutout.png"
import doctorPortraitImage from "../../images/role-portraits/doctor-cutout.png"
import spyPortraitImage from "../../images/role-portraits/spy-cutout.png"
import magicianPortraitImage from "../../images/role-portraits/magician-cutout.png"
import wizardPortraitImage from "../../images/role-portraits/wizard-cutout.png"

const RolesPage = () => (
  <Layout>
    <SEO title="Roles" />

    <Breadcrumbs title="Roles" headline="Eight roles, endless possibilities" />

    <Grid variant="textWrap">
      <Box>
        <Text as="p" variant="bodyLarge">Quest breathes new life into some of your favorite fantasy roleplaying tropes. From our poetry-spouting Fighter, to our charade-playing Magician, each role has surprising and interactive abilities that offer new styles of play.</Text>
        <Text as="p" variant="bodyLarge">Quest’s ability catalog has over 200 abilities, and each role’s abilities are unique. You’ll start with a small set of special abilities and then learn more as you grow and change during your adventure.</Text>
        <Text as="p" variant="bodyLarge">We invite you to learn more about each role and see examples of their unique abilities. There’s something for every player.</Text>
      </Box>
    </Grid>
    
    <Grid variant="base" columns={[1, 2]} sx={{ mt: [4, 6], pt: 6, gridGap: 6 }}>
      <LinkCard
        title="The Fighter"
        image={
          <EdgeOutImage
            src={fighterPortraitImage}
            imgWidth="80%"
            bgGradient="to bottom, #FBB363, #FAA85F"
          />
        }
        url="/roles/fighter"
      />

      <LinkCard
        title="The Invoker"
        image={
          <EdgeOutImage
            src={invokerPortraitImage}
            imgWidth="80%"
            bgGradient="to bottom, #183658, #0A1832"
          />
        }
        url="/roles/invoker"
      />

      <LinkCard
        title="The Ranger"
        image={
          <EdgeOutImage
            src={rangerPortraitImage}
            imgWidth="80%"
            bgGradient="to bottom, #AEDEE0, #AEDEE0"
          />
        }
        url="/roles/ranger"
      />

      <LinkCard
        title="The Naturalist"
        image={
          <EdgeOutImage
            src={naturalistPortraitImage}
            imgWidth="100%"
            bgGradient="135deg, #8ECB8F, #A2D398"
          />
        }
        url="/roles/naturalist"
      />

      <LinkCard
        title="The Doctor"
        image={
          <EdgeOutImage
            src={doctorPortraitImage}
            imgWidth="70%"
            bgGradient="to bottom, #F69C93, #F9AD95"
          />
        }
        url="/roles/doctor"
      />

      <LinkCard
        title="The Spy"
        image={
          <EdgeOutImage
            src={spyPortraitImage}
            imgWidth="45%"
            bgGradient="45deg, #EFDFEC, #DFD0E4"
          />
        }
        url="/roles/spy"
      />

      <LinkCard
        title="The Magician"
        image={
          <EdgeOutImage
            src={magicianPortraitImage}
            imgWidth="70%"
            bgGradient="45deg, #D75A94, #EC9B7F"
          />
        }
        url="/roles/magician"
      />

      <LinkCard
        title="The Wizard"
        image={
          <EdgeOutImage
            src={wizardPortraitImage}
            imgWidth="65%"
            bgGradient="to bottom, #79634B, #987C4D"
          />
        }
        url="/roles/wizard"
      />

    </Grid>
  </Layout>
)

export default RolesPage